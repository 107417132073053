import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar'

const Водоподготовка = () => (
    <Layout>
      <Helmet>
          <title>Водоподготовка</title>
      </Helmet>
      <Header 
        styleName='header header-water'
        title='Водоподготовка'/>
      <div className="columns">
        <div className="column content">
          <div className="content-text">Специалисты ЗАО "Inžinerinė ekologija" каждому клиенту подберут индивидуальную программу реагентной обработки, основываясь на данных обследования системы и анализов воды.</div>
          <div className="title-small content-title">Коррекционная обработка для паровых котлов и систем отопления</div>
          <div className="content-text"> Реагенты IN-ECO минимизируют процессы коррозии, исключают образование накипи, регулируют рН, создают условия для формирования прочной пассивационной (магненитной) пленки, что оптимизирует теплообмен, улучшает качество парообразования, снижает потребление топлива и увеличивает коэффициент полезного действия.</div>
          <table className="table content-table">
          <tbody>
            <tr>
              <td>Ингибиторы коррозии и накипи</td>
              <td>IN-ECO 301; 307; 308; 315</td>
            </tr>
            <tr>
              <td>Связыватели остаточного кислорода</td>
              <td>IN-ECO 324-2; 326</td>
            </tr>
            <tr>
              <td>Ингибиторы коррозии для пароконденсатных линий</td>
              <td>IN-ECO 332; 334; 336; 3263</td>
            </tr>
            <tr>
              <td>Для систем отопления (процесов термофикации)</td>
              <td>IN-ECO 301; 390; 392; 3553</td>
            </tr>
            <tr>
              <td>Пеногасители</td>
              <td>IN-ECO 302-31</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Стабилизационная обработка оборотных систем охлаждения</div>
          <div className="content-text">Реагенты IN-ECO полностью ингибируют отложения минерального и органического характера, минимизируют процессы коррозии, пассивируют различные типы металлов, что дает возможность работать на более высоких коэффициентах упаривания и длительно поддерживать эффективный теплообмен.</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Ингибиторы коррозии и накипи для открытых систем</td>
              <td>IN-ECO 101; 103; 114; 131; 133; 134; 135; 150; 151</td>
            </tr>
            <tr>
              <td>Ингибиторы коррозии и накипи для закрытых систем</td>
              <td>IN-ECO 160; 161; 170; 174</td>
            </tr>
            <tr>
              <td>Неоксидирующие биоциды</td>
              <td>IN-ECO 264; 267; 268; 2641</td>
            </tr>
            <tr>
              <td>Оксидирующие биоциды</td>
              <td>IN-ECO 210; 510; 511</td>
            </tr>
            <tr>
              <td>Биодисперсанты</td>
              <td>IN-ECO 250; 251</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Реагенты для мембранных технологий обратного осмоса и ультрафильтрации</div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Антискаланты</td>
              <td>IN-ECO 550; 551; 552; 553; 554</td>
            </tr>
            <tr>
              <td>Концентраты для промывки</td>
              <td>IN-ECO 535; 537; 538; 540; 541</td>
            </tr>
            <tr>
              <td>Консерванты</td>
              <td>IN-ECO 501; 502</td>
            </tr>
            <tr>
              <td>Дезинфекция и санация</td>
              <td>IN-ECO 510; 513; 515</td>
            </tr>
            <tr>
              <td>Дополнительные реагенты для предварительной обработки и коррекции</td>
              <td>IN-ECO 600; 210; 501</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Реагенты для обслуживания и промывки теплообменного оборудования </div>
          <table className="table content-table">
            <tbody>
            <tr>
              <td>Отмывка теплообменников и котлов</td>
              <td>IN-ECO 401; 402; 403; 424</td>
            </tr>
            <tr>
              <td>Пассивация и предстартовая обработка, обезжиривание</td>
              <td>IN-ECO 411; 412; 4121</td>
            </tr>
            <tr>
              <td>Коагулянты и флокулянты для предварительной обработки</td>
              <td>IN-ECO 600; 6025; 6015; 882; 850</td>
            </tr>
            </tbody>
          </table>
          <div className="title-small content-title">Ионообменные смолы и различные загрузки для фильтров</div>
          <div className="content-text">Предлагаем полный спектр смол Purolite (катионитные, анионитные) и разные наполнители для механических фильтров и фильтров обезжелезивания.</div>
        </div>
        <Sidebar
        analysis='Анкеты'
        boiler='Бойлер'
        cooling='Охлаждение'
        ro='Осмос'
        broshure='IN-ECO брошура'
        certificates='Сертификаты'
        russian={true}
        />
      </div>
    </Layout>

)

export default Водоподготовка